import convertBase64ImageToJpeg from "@/utils/convertBase64ImageToJpeg"
import { theme } from "@ikhokha/commons-ui/build/dist/cjs"
import { alpha, Box, Stack, Typography } from "@mui/material"
import Link from "@mui/material/Link"
import React, { ChangeEvent, useRef, useState } from "react"

const acceptedFileTypes = ["image/jpeg", "image/jpg", "image/png"]

const unsupportedFileTypeError = "Please upload image in a supported format (eg., PNG,JPEG OR PNG)."
type ImageUploadProps = {
	onValidFile: (_base64File: string) => void
	maxFileSizeInMB: number
}
export const FileSelector: React.FC<ImageUploadProps> = ({ onValidFile, maxFileSizeInMB }) => {
	const defaultPrompt = `PNG, JPEG or JPG (max. ${maxFileSizeInMB}MB)`
	const maximumFileSizeInBytes = maxFileSizeInMB * 1024 * 1024 // default to 3MB
	const maximumFileSizeError = `The image exceeds the maximum allowed file size of ${maxFileSizeInMB}MB.`
	const [fileSizeTooLarge, setFileSizeTooLarge] = useState<boolean>(false)
	const [invalidFileType, setInvalidFileType] = useState<boolean>(false)
	const fileInputRef = useRef<HTMLInputElement | null>(null)

	const errorState = invalidFileType || fileSizeTooLarge

	const errorStateBorder = `1px solid ${theme.palette.error.main}`
	const containerBorder = errorState ? errorStateBorder : `1px dashed ${alpha(theme.palette.text.secondary, 0.12)}`
	const containerBorderOnHover = errorState ? errorStateBorder : `1px dashed ${theme.palette.secondary.main}`
	const errorStateBackground = `${alpha(theme.palette.error.main, 0.04)}`
	const containerBackground = errorState ? errorStateBackground : ""
	const containerBackgroundOnHover = errorState ? errorStateBackground : `${alpha(theme.palette.secondary.main, 0.04)}`

	const promptElement = (text: string, color?: string) => (
		<Typography variant={"body2"} color={color}>
			{text}
		</Typography>
	)

	const errorPromptElement = (text: string) => promptElement(text, theme.palette.error.main)

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0]
			const reader = new FileReader()
			reader.addEventListener("load", () => {
				if (file) {
					// reset error state
					setFileSizeTooLarge(false)
					setInvalidFileType(false)

					if (!acceptedFileTypes.includes(file.type)) {
						setInvalidFileType(true)
						return
					}

					if (file.size > maximumFileSizeInBytes) {
						setFileSizeTooLarge(true)
						return
					}
					;(async () => {
						const jpegImage = await convertBase64ImageToJpeg(reader.result?.toString() || "")
						onValidFile(jpegImage)
					})()
				}
			})
			reader.readAsDataURL(event.target?.files[0])
		}
	}

	const handleClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click()
		}
	}

	return (
		<Stack gap={1} width={"100%"}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					gap: "1rem",
					padding: "24px 16px",
					cursor: "pointer",
					borderRadius: ".5rem",
					backgroundColor: containerBackground,
					border: containerBorder,
					":hover": {
						border: containerBorderOnHover,
						backgroundColor: containerBackgroundOnHover,
					},
				}}
				onClick={handleClick}
			>
				<input
					type="file"
					accept=".jpg, .jpeg, .png"
					style={{ display: "none" }}
					ref={fileInputRef}
					onChange={handleFileChange}
				/>

				<Link
					sx={{
						"&:hover": {
							textDecoration: "none",
						},
					}}
				>
					Click to upload
				</Link>
				<Box sx={{ width: 233, textAlign: "center" }}>
					{!errorState && promptElement(defaultPrompt)}
					{invalidFileType && errorPromptElement(unsupportedFileTypeError)}
					{fileSizeTooLarge && errorPromptElement(maximumFileSizeError)}
				</Box>
			</Box>
		</Stack>
	)
}
