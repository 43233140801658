/**
 * This function converts a base64 image of any type to a JPEG image.
 *
 * @param {string} base64Image - The base64 string of the source image.
 * @returns {Promise<string>} - A Promise that resolves with the JPEG image as a base64 string.
 */
const convertBase64ImageToJpeg = (base64Image: string): Promise<string> => {
	return new Promise((resolve, reject) => {
		const img = new Image()

		img.src = base64Image

		// Wait for the image to load
		img.onload = function () {
			const canvas = document.createElement("canvas")
			const ctx = canvas.getContext("2d")

			canvas.width = img.width
			canvas.height = img.height

			if (ctx) ctx.drawImage(img, 0, 0, img.width, img.height)
			else reject(new Error("Failed to load image"))

			const jpegDataUrl = canvas.toDataURL("image/jpeg", 1.0)

			// Resolve the Promise with the data URL
			resolve(jpegDataUrl)
		}

		img.onerror = function () {
			// Reject the Promise with an error message
			reject(new Error("Failed to load image"))
		}
	})
}
export default convertBase64ImageToJpeg
