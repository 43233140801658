import { getWrapperOverlay, HoverWrapper } from "@/builderComponents"
import { useGetWebstoreConfig } from "@/hooks"
import {
	ActionType,
	builderMenuReducerAtom,
	builderModeEnabledAtom,
	builderValuesAtom,
	EditItem,
	socialMediaDetailsAtom,
} from "@/state"
import { SocialPages } from "@/types"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import { useTheme } from "@mui/material/styles"
import { FacebookLogo, Icon, InstagramLogo, TiktokLogo } from "@phosphor-icons/react"
import { useAtom, useAtomValue, useSetAtom } from "jotai"
import React from "react"

type Target = "_blank" | "_self" | "_parent" | "_top"

const IconComponent = ({ IconComponent, href, target }: { IconComponent: Icon; href: string; target?: Target }) => {
	const theme = useTheme()

	return (
		<IconButton
			color="inherit"
			sx={{
				"&:hover": {
					color: theme.palette.primary.main,
				},
			}}
			LinkComponent={"a"}
			target={target ?? "_self"}
			href={href}
		>
			<IconComponent />
		</IconButton>
	)
}

type Props = {
	socials: SocialPages
	builderMode?: boolean
}
export const Socials = ({ socials: props, builderMode = false }: Props) => {
	const facebook = (props.facebook || builderMode) && (
		<IconComponent IconComponent={FacebookLogo} href={props.facebook ?? ""} target={"_blank"} />
	)

	const instagram = (props.instagram || builderMode) && (
		<IconComponent IconComponent={InstagramLogo} href={props.instagram ?? ""} target={"_blank"} />
	)

	const tiktok = (props.tiktok || builderMode) && (
		<IconComponent IconComponent={TiktokLogo} href={props.tiktok ?? ""} target={"_blank"} />
	)

	return (
		<>
			{(builderMode || props.facebook || props.instagram || props.tiktok) && (
				<Stack gap={".63rem"} direction={"row"} alignItems={"center"} sx={{ height: "3.7rem" }} data-scroll-to={EditItem.Socials}>
					{facebook}
					{instagram}
					{tiktok}
				</Stack>
			)}
		</>
	)
}

export const SocialsBuilder = () => {
	const builderMode = useAtomValue(builderModeEnabledAtom)

	const setSocialMediaDetails = useSetAtom(socialMediaDetailsAtom)

	const [menuState, dispatch] = useAtom(builderMenuReducerAtom)

	const { webstoreConfigDraft } = useGetWebstoreConfig()

	const { values } = useAtomValue(builderValuesAtom)
	const socials = values.socialMediaDetails as SocialPages

	React.useEffect(() => {
		setSocialMediaDetails(webstoreConfigDraft?.socialPages ?? {})
	}, [])

	const active = menuState.activeEditingItem === EditItem.Socials

	const handleEditClick = (element: HTMLElement) =>
		dispatch({ type: ActionType.EditItem, payload: { editItemType: EditItem.Socials, element } })

	const builderSocials: SocialPages = {
		facebook: socials.facebook ?? "",
		instagram: socials.instagram ?? "",
		tiktok: socials.tiktok ?? "",
	}

	/**
	 * TO DO:
	 * The relevant edit menu can reference this ref to see if the wrapper's state is active.
	 * The edit menu can also handle click events to toggle the wrapper's forced active state.
	 */

	if (builderMode) {
		return (
			<HoverWrapper overlay={getWrapperOverlay(active, handleEditClick)} labelText="Social media" active={active}>
				<Socials socials={builderSocials} builderMode={builderMode} />
			</HoverWrapper>
		)
	}
	return <Socials socials={builderSocials} />
}
