import AddIcon from "@mui/icons-material/Add"
import RemoveIcon from "@mui/icons-material/Remove"
import { useTheme } from "@mui/material"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import React from "react"
import { CartItem, useCartContext } from "@/hooks/useCart"

interface QuantitySelectorForCartProps {
	item: CartItem
}
export default function QuantitySelectorForCart({ item }: Readonly<QuantitySelectorForCartProps>) {
	const { palette } = useTheme()

	const { cart, addItemToCart, removeItemFromCart } = useCartContext()
	const cartItem = cart.find((cartItem) => cartItem.id === item.id && cartItem.selectedVariantId === item.selectedVariantId)
	const decrementColor = cartItem?.quantity === 1 ? palette.grey["400"] : palette.common.black

	const handleCartItemQuantity = (type: "increment" | "decrement") => {
		if (!cartItem) return
		const item = cartItem

		if (type === "increment") {
			addItemToCart(item)
		}

		if (type === "decrement") {
			if (item.quantity <= 1) return
			removeItemFromCart(item)
		}
	}

	return (
		<Stack direction="row" width="7.5rem" gap=".5rem" alignItems="flex-end" justifyContent="flex-end">
			<Stack padding=".1rem" border={`1px solid ${palette.grey["400"]}`} borderRadius=".25rem" color={decrementColor}>
				<RemoveIcon onClick={() => handleCartItemQuantity("decrement")} sx={{ cursor: "pointer" }} />
			</Stack>
			<TextField
				inputProps={{
					style: {
						textAlign: "center",
						padding: "1rem 0",
					},
				}}
				name="quantity"
				variant="standard"
				value={cartItem?.quantity}
				fullWidth
				size="medium"
			/>
			<Stack padding=".1rem" border={`1px solid ${palette.grey["400"]}`} borderRadius=".25rem">
				<AddIcon onClick={() => handleCartItemQuantity("increment")} sx={{ cursor: "pointer" }} />
			</Stack>
		</Stack>
	)
}
