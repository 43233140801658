import MUIIconButton from "@mui/material/IconButton"
import React from "react"

type IconButtonProps = React.PropsWithChildren<{ onClick?: () => void }>

const IconButton = ({ children, onClick }: IconButtonProps) => {
	return (
		<MUIIconButton
			onClick={onClick}
			size="small"
			sx={{
				gap: ".5rem",
				":hover": {
					backgroundColor: "transparent",
				},
			}}
		>
			{React.Children.toArray(children)}
		</MUIIconButton>
	)
}

export default IconButton
