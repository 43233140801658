import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useAtom, useAtomValue } from "jotai"
import { builderMenuReducerAtom, builderModeEnabledAtom, EditItem, webstoreNameAtom } from "@/state"
import { useUpdateDraftWebstoreConfigMutation, useUserDetails } from "@/hooks"
import Dialog from "@mui/material/Dialog"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { IkhokhaThemeProvider } from "@/builderComponents"
import Box from "@mui/material/Box"
import { Swap } from "@phosphor-icons/react"
import { theme } from "@ikhokha/commons-ui/build/dist/cjs/theme"
import BannerImage from "@/assets/BannerImage"
import imageUrlToBase64 from "@/utils/imageToBase64"
import { Fade } from "@/components/Fade"
import TemplateComponentThemeProvider from "@/components/Template/TemplateThemeProvider"
import Loader from "@/components/Loader"
import { createMDSPath } from "@/utils"
import { useRouter } from "next/navigation"
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime"
import { useWebstore } from "@/hooks/useWebstore"

export const MerchantLogoBuilder = (props: React.PropsWithChildren) => {
	const router = useRouter()
	const webstoreContext = useWebstore()
	const { webstore } = webstoreContext
	const businessLogo = webstore?.businessLogo

	const { store } = useUserDetails()

	const builderMode = useAtomValue(builderModeEnabledAtom)
	const [menuState] = useAtom(builderMenuReducerAtom)
	const active = useMemo(() => menuState.activeEditingItem === EditItem.Logo, [menuState])
	const [imageLoadError, setImageLoadError] = useState(false)
	const webstoreName = useAtomValue(webstoreNameAtom)

	const [redirectPending, setRedirectPending] = useState(false)
	const { mutate, isSuccess } = useUpdateDraftWebstoreConfigMutation()

	useEffect(() => {
		if (redirectPending && isSuccess) {
			window.location.href = createMDSPath("/business/profile?action=edit-logo")
		}
	}, [redirectPending, isSuccess])

	const handleAddLogoRedirect = () => {
		setRedirectPending(true)
		mutate()
	}

	const logo = businessLogo?.cropped ? `${process.env.NEXT_PUBLIC_BUSINESS_LOGO_URL}/${businessLogo.cropped}` : undefined

	const content = useMemo(
		() => (
			<Box height={"3rem"} width={"fit-content"} display={"flex"} alignItems={"center"}>
				{logo ? (
					<Logo onImageLoadError={() => setImageLoadError(true)} imageUrl={logo} router={router}>
						{props.children}
					</Logo>
				) : (
					<Typography onClick={() => router.push(`/${webstoreName}`)}>{store?.tradingName}</Typography>
				)}
			</Box>
		),
		[businessLogo, logo, active, imageLoadError, store],
	)

	// prevent re-renders on hover of HoverWrapper
	const overlay = useCallback(() => {
		return <MerchantLogoWrapperOverlay onHandleAddLogoRedirect={handleAddLogoRedirect} />
	}, [businessLogo])

	if (builderMode) {
		return (
			<LogoHoverWrapper overlay={overlay} labelText="Logo" active={active}>
				{content}
				<Dialog open={redirectPending}>
					<Stack
						direction={"row"}
						display={"flex"}
						justifyContent={"space-between"}
						alignItems={"center"}
						padding={"10px 10px 0 20px"}
					>
						<Typography variant={"h6"} fontSize={18}>
							Upload logo
						</Typography>
						<IconButton onClick={() => setRedirectPending(false)}>
							<CloseIcon />
						</IconButton>
					</Stack>
					<Box sx={{ padding: "100px" }}>
						<Stack alignItems="center">
							<Loader text={"Hold on, we’re redirecting you to add a logo."} />
						</Stack>
					</Box>
				</Dialog>
			</LogoHoverWrapper>
		)
	}

	return <>{content}</>
}

type MerchantLogoWrapperOverlayProps = {
	onHandleAddLogoRedirect: () => void
}
const MerchantLogoWrapperOverlay: React.FC<MerchantLogoWrapperOverlayProps> = ({ onHandleAddLogoRedirect }) => {
	return (
		<Box
			sx={{
				position: "absolute",
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 1,
				height: "100%",
				padding: ".625rem",
				boxSizing: "border-box",
				backgroundColor: "rgba(0, 158, 172, 0.08)",
			}}
		>
			<Stack direction={"row"} gap={2}>
				<Button
					startIcon={<Swap />}
					size="small"
					variant="contained"
					sx={{
						boxShadow: 0,
						borderRadius: "10px",
						textTransform: "none",
					}}
					onClick={onHandleAddLogoRedirect}
				>
					Change logo
				</Button>
			</Stack>
		</Box>
	)
}

type LogoProps = {
	imageUrl?: string
	onImageLoadError?: () => void // optional because no error modal will show on public facing
	router: AppRouterInstance
}

export const Logo = (props: React.PropsWithChildren<LogoProps>) => {
	const webstoreName = useAtomValue(webstoreNameAtom)
	const [image, setImage] = useState<string>(BannerImage)

	useEffect(() => {
		if (props.imageUrl) {
			fetchAndConvertImageToBase64(props.imageUrl)
				.then((base64) => setImage(base64))
				.catch(() => {
					console.error("Error converting image")
					props.onImageLoadError?.()
				})
		} else {
			setImage(BannerImage)
		}
	}, [props.imageUrl])

	return (
		<Stack
			justifyContent={"center"}
			height={"inherit"} // root
			data-scroll-to={EditItem.Logo}
			onClick={() => props.router.push(`/${webstoreName}`)}
			sx={{
				cursor: props.imageUrl ? "pointer" : "default",
			}}
		>
			<img src={image} alt="" height={"100%"} />

			<Box
				sx={{
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "inherit",
				}}
			/>
			<Box
				sx={{
					zIndex: 1,
				}}
			>
				{props.children}
			</Box>
		</Stack>
	)
}

const fetchAndConvertImageToBase64 = async (imageUrl: string): Promise<string> => {
	const { base64, error } = await imageUrlToBase64(imageUrl)
	if (!error && base64) {
		return base64
	} else {
		console.error("Error converting image:", error)
		throw new Error("Error converting image")
	}
}

const Tag = ({ text }: { text: string }) => {
	const [top, setTop] = React.useState(0)
	const ref = React.useRef<HTMLDivElement>(null)

	useLayoutEffect(() => {
		if (ref.current) {
			setTop(ref.current.clientHeight)
		}
	}, [])

	return (
		<Box
			ref={ref}
			sx={{
				paddingX: ".25rem",
				border: "none",
				zIndex: 2,
				position: "absolute",
				top: -top + hoverWrapperPositionProps.top,
				backgroundColor: "secondary.main",
				left: -2,
			}}
		>
			<Typography variant="caption" color={"common.white"}>
				{text}
			</Typography>
		</Box>
	)
}

const hoverWrapperPositionProps = {
	top: -2,
	left: -2,
	right: -2,
	bottom: -2,
}

export type HoverWrapperProps = {
	labelText: string
	overlay?: (props: React.PropsWithChildren) => JSX.Element
	/**
	 * If true, the hover effect will change to a solid border.
	 */
	active?: boolean
	onClick?: React.MouseEventHandler<HTMLDivElement>
}

const LogoHoverWrapper = React.forwardRef(function HoverWrapper(props: React.PropsWithChildren<HoverWrapperProps>, ref) {
	const [isHovered, setIsHovered] = React.useState(false)

	const handleMouseOver: React.MouseEventHandler<HTMLDivElement> = (event) => {
		event.stopPropagation()
		event.preventDefault()
		setIsHovered(true)
	}

	const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = () => {
		setIsHovered(false)
	}

	const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
		props.onClick?.(event)
	}

	/**
	 * Because this is conditionally rendered based on the hover state,
	 * we memoize it to prevent unnecessary re-renders.
	 */
	const hoverComponent = React.useMemo(
		() =>
			props.labelText ? (
				<Fade>
					<Tag text={props.labelText} />
					<Box
						sx={{
							position: "absolute",
							...hoverWrapperPositionProps,
							pointerEvents: "none",
							transition: "all 0.4s ease-in",
							border: `2px ${props.active ? "solid" : "dotted"} ${theme.palette.secondary.main}`,
							zIndex: 2,
						}}
					/>
				</Fade>
			) : undefined,
		[props.labelText, props.active],
	)

	return (
		<IkhokhaThemeProvider>
			<Box
				onMouseOut={handleMouseLeave}
				/**
				 * Using mouseOver and mouseOut better supports whether children are hovered or not.
				 */
				onMouseOver={handleMouseOver}
				sx={{
					width: "100%",
					cursor: "pointer",
					height: "inherit",
					position: "relative", // Ensures the hover effect is contained within the parent element as it uses absolute positioning
					display: "inline-block",
				}}
				onClick={handleClick}
				ref={ref}
			>
				{(isHovered || props.active) && hoverComponent}
				<Box
					sx={{
						position: "relative",
						height: "inherit",
						width: "100%",
					}}
				>
					{props.overlay && (isHovered || props.active) && React.createElement(props.overlay)}
					{/* needs to override the iKhokhas theme */}
					<TemplateComponentThemeProvider>{props.children}</TemplateComponentThemeProvider>
				</Box>
			</Box>
		</IkhokhaThemeProvider>
	)
})
