import { palette } from "@ikhokha/commons-ui/build/dist/cjs/palette"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { Image } from "@phosphor-icons/react"
import React from "react"

type ImageUploadProps = {
	onClick: () => void
}
export const ChangeImage: React.FC<ImageUploadProps> = ({ onClick }) => (
	<Stack
		direction={"row"}
		onClick={onClick}
		gap={1}
		alignItems={"center"}
		sx={{
			color: palette.secondary.main,
			":hover": {
				cursor: "pointer",
			},
		}}
	>
		<Image size={18} />
		<Typography color={palette.secondary.main}> Change image</Typography>
	</Stack>
)
