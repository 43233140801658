import { LayoutBox } from "@/components/Template/components/PublicFooterLayout"
import { useGetWebstorePolicyConfig } from "@/hooks"
import { theme } from "@ikhokha/commons-ui/build/dist/cjs/theme"
import { useMediaQuery, useTheme } from "@mui/material"
import Link from "@mui/material/Link"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

export function FooterBar() {
	const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
	const { webstoreName } = useGetWebstorePolicyConfig()
	const policiesUrl = `/${webstoreName}/terms-and-conditions`
	const { palette } = useTheme()

	return (
		<Stack padding="1.5rem 0" bgcolor={palette.grey[100]}>
			<LayoutBox>
				<Stack
					width="100%"
					direction={isDesktop ? "row" : "column"}
					justifyContent="space-between"
					gap={isDesktop ? "0" : "1rem"}
				>
					<Typography variant="body1">
						Powered by{" "}
						<Link color="text.secondary" href="https://ikhokha.com" target="_blank">
							iKhokha
						</Link>
					</Typography>

					<PolicyLink href={policiesUrl} text="PAIA manual" />
					<PolicyLink href={policiesUrl} text="Privacy policy" />
					<PolicyLink href={policiesUrl} text="Returns & refunds" />
					<PolicyLink href={policiesUrl} text="Delivery policy" />
				</Stack>
			</LayoutBox>
		</Stack>
	)
}

function PolicyLink({ href, text }: Readonly<{ href: string; text: string }>) {
	return (
		<Typography component={Link} href={href} variant="body1" color="text.secondary" underline="always">
			{text}
		</Typography>
	)
}
