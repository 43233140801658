import MaxWidth from "@/components/MaxWidth"
import {
	AboutUs,
	AboutUsBuilder,
	Socials,
	SocialsBuilder,
	StoreDetails,
	StoreDetailsBuilder,
} from "@/components/Template/components"
import { builderModeEnabledAtom, builderValuesAtom } from "@/state"
import { Contact, SocialPages, WebstoreConfig } from "@/types"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { useAtomValue } from "jotai"
import React, { PropsWithChildren } from "react"

export const PublicFooterLayout = (props: { publicFacingWebstoreConfig: Omit<WebstoreConfig, "currentStep"> }) => {
	const aboutUs = props.publicFacingWebstoreConfig.aboutUs ?? ""
	const socialPages = props.publicFacingWebstoreConfig.socialPages
	const storeDetails = props.publicFacingWebstoreConfig.contact

	return <FooterLayout aboutUs={aboutUs} socialPages={socialPages} storeDetails={storeDetails} />
}

type FooterLayoutPreviewProps = {
	aboutUs: string
	socialPages?: SocialPages
	storeDetails?: Contact
}
const FooterLayout = ({ aboutUs, socialPages, storeDetails }: FooterLayoutPreviewProps) => {
	const someSocialPages = socialPages?.facebook || socialPages?.instagram || socialPages?.instagram

	return (
		<LayoutBox>
			<Box paddingY={"1.5rem"}>
				<Grid container spacing={5.25}>
					{(aboutUs || someSocialPages) && (
						<Grid md={4}>
							<Stack>
								{aboutUs && <AboutUs aboutUs={aboutUs} />}
								{socialPages && <Socials socials={socialPages} />}
							</Stack>
						</Grid>
					)}
					{storeDetails && (
						<Grid md={4}>
							<StoreDetails contactDetails={storeDetails} />
						</Grid>
					)}
					<Grid md={4}>
						{/* To be implemented post launch */}
						{/*	<BusinessHoursBuilder />*/}
					</Grid>
				</Grid>
			</Box>
		</LayoutBox>
	)
}

export const FooterLayoutBuilder = () => {
	const builderModeEnabled = useAtomValue(builderModeEnabledAtom)

	if (builderModeEnabled) {
		return (
			<LayoutBox>
				<Box paddingY={"1.5rem"}>
					<Grid container spacing={5.25}>
						<Grid md={4}>
							<Stack>
								<AboutUsBuilder />
								<SocialsBuilder />
							</Stack>
						</Grid>
						<Grid md={4}>
							<StoreDetailsBuilder />
						</Grid>
						<Grid md={4}>
							{/* To be implemented post launch */}
							{/*	<BusinessHoursBuilder />*/}
						</Grid>
					</Grid>
				</Box>
			</LayoutBox>
		)
	}

	return <FooterLayoutPreview />
}

/**
 * This component is visible when the Merchant has switched to preview
 * mode in the site builder
 * @constructor
 */
const FooterLayoutPreview = () => {
	const { values } = useAtomValue(builderValuesAtom)

	return (
		<FooterLayout
			aboutUs={values.aboutUs as string}
			socialPages={values.socialMediaDetails as SocialPages}
			storeDetails={values.storeDetails as Contact}
		/>
	)
}

export const LayoutBox: React.FC<PropsWithChildren> = ({ children }) => (
	<Box paddingX={{ xs: "1rem", sm: "5rem" }}>
		<MaxWidth>{children}</MaxWidth>
	</Box>
)
