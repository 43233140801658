import IkLoader from "@/assets/iK-Load-Animation.gif"
import Typography from "@mui/material/Typography"

type LoaderProps = {
	text?: string
}

const Loader = (props: LoaderProps) => {
	return (
		<>
			<img src={IkLoader.src} alt="Loading" />
			{props.text && <Typography variant="body1">{props.text}</Typography>}
		</>
	)
}

export default Loader
