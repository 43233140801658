import contextFactory from "@/provider/ContextFactory"
import { FC, ReactNode, useEffect, useState } from "react"
import { useParams } from "next/navigation"
import { useGetWebstoreConfigByName } from "@/hooks/permalink"
import { WebstorePermalink } from "@/types"
import { PermalinkResponseCodes } from "@/enums"

type Context = {
	id: string
	webstore?: WebstorePermalink
	isLoading: boolean
}
const defaultStoreInfoContext: Context = {
	id: "",
	webstore: undefined,
	isLoading: false,
}

const { ProviderWrapper: BaseStoreInfoProvider, useContext: useWebstore } = contextFactory<Context>(defaultStoreInfoContext)

const WebstoreProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const { webstoreName: id } = useParams<{ webstoreName: string }>()
	const [isLoading, setIsLoading] = useState(true)
	const [webstore, setWebstore] = useState<WebstorePermalink | undefined>(undefined)

	const data = useGetWebstoreConfigByName(id)

	useEffect(() => {
		if (data.data) {
			if (data.data.responseCode !== PermalinkResponseCodes.Perm_00) return // fix error handling
			setWebstore(data.data.permalink)
			setIsLoading(false)
		}
	}, [data])

	return <BaseStoreInfoProvider context={{ id, webstore, isLoading }}>{children}</BaseStoreInfoProvider>
}

export { WebstoreProvider, useWebstore }
