const fetchBlob = async (url: string): Promise<Blob> => {
	const response = await fetch(url)
	if (!response.ok) {
		throw new Error(`Failed to fetch image: ${response.statusText}`)
	}
	return await response.blob()
}

const convertBlobToBase64 = (blob: Blob): Promise<string> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(blob)
		reader.onloadend = () => {
			const base64data = reader.result as string
			resolve(base64data)
		}
		reader.onerror = () => {
			reject(new Error("Failed to convert Blob to base64"))
		}
	})
}

const imageUrlToBase64 = async (url: string): Promise<{ base64: string | null; error: Error | null }> => {
	try {
		const blob = await fetchBlob(url)
		const base64String = await convertBlobToBase64(blob)
		return { base64: base64String, error: null }
	} catch (err) {
		return { base64: null, error: err as Error }
	}
}

export default imageUrlToBase64
