import { ChangeImage } from "@/components/ImageUpload/ChangeImage"
import { FileSelector } from "@/components/ImageUpload/FileSelector"
import { ImageCrop } from "@/components/ImageUpload/ImageCrop"
import CloseIcon from "@mui/icons-material/Close"
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack } from "@mui/material"
import { FC, useEffect, useState } from "react"

type ImageUploadProps = {
	title: string
	onSave: (base64File: string) => void
	isOpen: boolean
	onClose: () => void
	inputImageBase64File?: string
	maxFileSizeInMB?: number
	loading: boolean
}

const ImageUpload: FC<ImageUploadProps> = ({
	isOpen,
	onClose,
	title,
	maxFileSizeInMB = 3,
	onSave,
	inputImageBase64File,
	loading,
}) => {
	const [isImageCropOpen, setIsImageCropOpen] = useState<boolean>(!!inputImageBase64File)
	const [base64File, setBase64File] = useState<string>(inputImageBase64File ?? "")
	const [croppedBase64FileRef, setCroppedBase64FileRef] = useState<string>("") // need to re-render when cropped image is set

	const handleClose = () => onClose()

	const handleFileChange = (base64File: string) => {
		setBase64File(base64File)
		setIsImageCropOpen(true)
	}

	const handleCroppedImage = (croppedImageBase64File: string) => {
		setCroppedBase64FileRef(croppedImageBase64File)
	}

	const resetSelection = () => {
		setBase64File("")
		setCroppedBase64FileRef("")
		setIsImageCropOpen(false)
	}

	const handleSave = () => {
		onSave(croppedBase64FileRef)
	}

	useEffect(() => {
		if (inputImageBase64File) {
			setBase64File(inputImageBase64File)
			setIsImageCropOpen(true)
		}
	}, [inputImageBase64File])

	return (
		<Dialog open={isOpen}>
			<DialogTitle>{title}</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={handleClose}
				sx={{
					position: "absolute",
					right: "1rem",
					top: "1rem",
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent sx={{ paddingBottom: 3 }}>
				<Stack
					width={"100%"}
					direction={"column"}
					alignItems={"center"}
					justifyContent={"space-between"}
					sx={{ minHeight: 210, width: 396 }}
					gap={3}
				>
					{!isImageCropOpen && <FileSelector onValidFile={handleFileChange} maxFileSizeInMB={maxFileSizeInMB} />}
					{isImageCropOpen && (
						<Stack direction={"column"} alignItems={"center"} gap={1} width={"100%"}>
							<ImageCrop inputImageBase64File={base64File} onImageCrop={handleCroppedImage} />
							<ChangeImage onClick={resetSelection} />
						</Stack>
					)}

					<Button disabled={!croppedBase64FileRef || loading} fullWidth onClick={handleSave}>
						{loading ? "Saving" : "Save"}
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	)
}

export default ImageUpload
