import React, { PropsWithChildren, createContext } from "react"

/**
 * This is a factory function that returns a ProviderWrapper and useContext function.
 * The ProviderWrapper is a component that wraps the children with a Context.Provider.
 * The useContext function is a hook that returns the context value.
 *
 * Pass the correct type to the generic parameter, which will be the type of the context value.
 *
 * @example
 * const defaultContext = { name: 'John' };
 * const { ProviderWrapper, useContext } = contextFactory<{name: 'string'}>(defaultContext);
 *
 * const MyComponent = () => {
 *  const { name } = useContext(); // correct type due to type passed to contextFactory
 * return <div>{name}</div>;
 * };
 *
 * const App = () => {
 * return (
 * <ProviderWrapper>
 * <MyComponent />
 * </ProviderWrapper>
 * );
 * };
 *
 * @param defaultContext
 * @returns
 */
const contextFactory = <TContext,>(
	defaultContext: TContext,
): {
	// eslint-disable-next-line no-unused-vars
	ProviderWrapper: (props: PropsWithChildren<{ context?: TContext }>) => JSX.Element
	useContext: () => TContext
} => {
	const Context = createContext<TContext>(defaultContext)

	const useContext = () => React.useContext(Context)

	const ProviderWrapper = ({ children, context }: PropsWithChildren<{ context?: TContext }>) => {
		return <Context.Provider value={context ?? defaultContext}>{children}</Context.Provider>
	}

	return { ProviderWrapper, useContext }
}

export default contextFactory
