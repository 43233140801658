import { palette } from "@ikhokha/commons-ui/build/dist/cjs/palette"
import { styled } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import MUIRadio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import React from "react"

const Radio = styled(MUIRadio)(() => ({
	"&.MuiRadio-root": {
		color: palette.common.black,
	},
}))

type Props = {
	value: string
	onChange: (updatedValue: string) => void
}
export default function TypeFilter(props: Readonly<Props>) {
	return (
		<Stack>
			<Typography variant="subtitle2">Items</Typography>
			<FormControl>
				<RadioGroup
					aria-labelledby="items-radio-buttons-group-label"
					defaultValue="all"
					name="items-buttons-group"
					value={props.value}
					onChange={(_, updatedValue) => props.onChange(updatedValue)}
				>
					<FormControlLabel value="all" control={<Radio />} label="All products & services" />
					<FormControlLabel value="product" control={<Radio />} label="Products only" />
					<FormControlLabel value="service" control={<Radio />} label="Services only" />
				</RadioGroup>
			</FormControl>
		</Stack>
	)
}
