import { getImageUploadUrl, getWebstoreCatalogue, getWebstoreConfigByName, uploadImage } from "@/api"
import { WebstoreConfig } from "@/types"
import { getStaleTimeInSeconds } from "@/utils"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"

export enum PermalinkQueryKeys {
	ImageUploadUrl = "ImageUploadUrl",
}

export const useGetImageUploadUrlByOwner = (owner: string) => {
	const getImageUploadUrlByOwner = useQuery({
		queryKey: [PermalinkQueryKeys.ImageUploadUrl],
		queryFn: () => getImageUploadUrl(owner),
		staleTime: getStaleTimeInSeconds(5 * 60),
		enabled: !!owner,
	})

	return getImageUploadUrlByOwner
}

export const useImageUploadWithSignedUrl = (onSuccess: (base64String: string) => void, updateData: () => void) => {
	const queryClient = useQueryClient()
	const imageMutation = useMutation({
		mutationFn: async (params: { url: string; base64String: string }) => await uploadImage(params.url, params.base64String),
		onSuccess: async (_data, variables) => {
			updateData()
			onSuccess(variables.base64String)
			await queryClient.invalidateQueries({ queryKey: [PermalinkQueryKeys.ImageUploadUrl] })
		},
	})
	return imageMutation
}

export const useGetWebstoreConfigByName = (webstoreName: string) => {
	const getWebstoreConfigByNameQuery = useQuery({
		queryKey: ["customerWebstoreConfig"],
		queryFn: () => getWebstoreConfigByName(webstoreName),
		enabled: !!webstoreName,
	})
	return getWebstoreConfigByNameQuery
}

export const useGetWebstoreCatalogue = (owner?: string, webstoreConfig?: WebstoreConfig) => {
	const getWebstoreCatalogueQuery = useQuery({
		queryKey: ["customerWebstoreCatalogue"],
		queryFn: () => getWebstoreCatalogue(owner, webstoreConfig),
		enabled: !!owner,
	})
	return getWebstoreCatalogueQuery
}
