import MaxWidth from "@/components/MaxWidth"
import ShoppingCart from "@mui/icons-material/ShoppingCartOutlined"
import { Badge, useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import { MagnifyingGlass } from "@phosphor-icons/react"
import { MerchantLogoBuilder } from "@/components/BusinessLogo"
import React from "react"
import { useCartContext } from "@/hooks/useCart"
import CartDrawer from "@/components/Drawer/drawer"
import { useAtom, useSetAtom } from "jotai"
import { showCartBannerAtom, showCartDrawerAtom } from "@/state/cart"
import { builderModeEnabledAtom } from "@/state"
import { useAtomValue } from "jotai/index"

type NavbarProps = {
	centerLogo?: boolean
	sticky: boolean
}

export const Navbar = ({ centerLogo, sticky }: NavbarProps) => {
	const builderModeEnabled = useAtomValue(builderModeEnabledAtom)
	const { palette } = useTheme()
	const { cart } = useCartContext()
	const [showCart, setShowCart] = useAtom(showCartDrawerAtom)
	const setShowBannerOnAddItemToCart = useSetAtom(showCartBannerAtom)

	const totalCartItems = cart.map((item) => item.quantity).reduce((acc, curr) => acc + curr, 0)

	return (
		<Stack
			sx={{
				backgroundColor: "common.white",
				position: sticky ? "sticky" : "relative",
				top: 0,
				zIndex: sticky ? 2 : 0,
			}}
		>
			<Stack
				paddingX={{
					xs: "1rem",
					sm: "5rem",
				}}
			>
				<MaxWidth>
					<Stack height={"4rem"} justifyContent={"space-between"} alignItems={"center"} direction={"row"}>
						<Stack width={"100%"} alignItems={centerLogo ? "center" : "start"}>
							<Box height={"3rem"} width={"200px"}>
								<MerchantLogoBuilder />
							</Box>
						</Stack>
						<Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} gap={"1.5rem"}>
							<IconButton>
								<MagnifyingGlass />
							</IconButton>
							{!builderModeEnabled ? (
								<Button
									variant="text"
									color="inherit"
									startIcon={
										<Badge
											badgeContent={totalCartItems}
											sx={{
												"& .MuiBadge-badge": {
													backgroundColor: "#1d1d1b",
													color: "#fff",
												},
											}}
										>
											<ShoppingCart />
										</Badge>
									}
									onClick={() => {
										if (totalCartItems <= 0) return
										setShowBannerOnAddItemToCart(false)
										setShowCart(true)
									}}
								>
									Cart
								</Button>
							) : (
								<Button variant="text" color="inherit" startIcon={<ShoppingCart />}>
									Cart
								</Button>
							)}
						</Stack>
					</Stack>
				</MaxWidth>
			</Stack>
			<Divider sx={{ height: 0, borderColor: palette.grey["200"] }} />
			<CartDrawer open={showCart} onClose={() => setShowCart(false)} />
		</Stack>
	)
}
