import { Stack, useTheme } from "@mui/material"
import React, { PropsWithChildren } from "react"

const MaxWidth: React.FC<PropsWithChildren> = ({ children }) => {
	const theme = useTheme()
	return (
		<Stack width={"100%"} maxWidth={theme.breakpoints.values.md} marginX={"auto"}>
			{children}
		</Stack>
	)
}

export default MaxWidth
